let result, loadingPromise;

const load = () => {
  if (result) return Promise.resolve(result);

  if (loadingPromise) return loadingPromise;

  loadingPromise = Promise.all([
    import('./cmp-tray-alert'),
  ])
    .then(([module]) => {
      result = module;

      return module;
    })
    .catch((err) => console.error(`Failed to load "trayAlert" =>`, err));

  return loadingPromise;
};

const cmpTrayAlert = {
  show: (message, type, delay, callback, id, $target) => {
    load().then(({ cmpTrayAlert }) => {
      cmpTrayAlert.show(message, type, delay, callback, id, $target);
    });
  },
  init: (config) => {
    load().then(({ cmpTrayAlert }) => {
      cmpTrayAlert.init(config);
    });
  },
};

export { cmpTrayAlert };
