import $ from 'jquery';

import {
  initEmbeds,
  setCookie,
  commentsCountUpdate,
  loadCommentsNumber,
  checkAndFilterCoralCommentsAmountToShow,
  disableAdsInEmbedVideo,
} from './utils/fn';
import { loadDisqusComments } from './jquery-lazy-load-3rd-party';
import { topComments } from './top-comments';
// import $ from 'jquery';
//import ShareBoxInline from '../../../components/shares-box/share-box-inline';

const edition = document.body.dataset.edtn || '';

export const msntConsent = {
  params: null,
  usedMethods: {
    initEmbeds: new Map(),
    initShareBlock: new Map(),
    initEmbedVideo: new Map(),
    initFeaturedVideo: new Map(),
    initCommentsforCPusers: new Map(),
  },
  shareBlocks: [],
  buttonSelector: '.ot-sdk-show-settings',
  excludeEditions: ['br', 'ev_br'],
  currentEnvironment: document.body.dataset.env,
  isDevelopment: false,

  // code to check for inside params.purpose.consents
  CONSENT_CODE: 3,

  initialized: false,

  consentConfig: window?.jsVars?.consent || null,

  init() {
    if (this.initialized) return;

    this.initialized = true;

    if (this.isDevelopment || this.isExcludedEdition()) {
      this.handleDevelopmentOrNotApplicable();
    }

    // this will trigger on params changed when Quantcast is used
    // also this will trigger once on start for OneTrust which should set initial params values
    window.__tcfapi?.('addEventListener', 2, (tcData, listenerSuccess) => {
      this.params = tcData;
      this.paramsChanged();
    });

    this.initEvents();
    this.initOneTrust();
    this.initSourcePoint();
  },

  initEvents() {
    const $editSettingsButton = $(this.buttonSelector);

    $editSettingsButton.on('click', () => {
      window.__tcfapi?.('displayConsentUi', 2, () => {});

      // required for SourcePoint integration
      window?._sp_?.gdpr?.loadPrivacyManagerModal?.();
    });

    $('body').on('click', '.ms-onetrust_stub-button', () => {
      $editSettingsButton.trigger('click');
    });
  },

  initOneTrust() {
    if (typeof window.OneTrust?.OnConsentChanged !== 'function') return;

    window.OneTrust.OnConsentChanged(() => {
      window.__tcfapi?.('getTCData', 2, (tcData, success) => {
        // tcData.purpose.consents is empty locally, but on production everything seems to be working just fine
        this.params = tcData;
        this.paramsChanged();
      });
    });
  },

  isLoggedIntoContentpass() {
    return document.cookie.includes('_cpauthhint=1');
  },

  initSourcePoint() {
    const isSourcePoint = this.consentConfig?.provider === 'SourcePoint';
    if (!isSourcePoint) return;

    const cfg = this.consentConfig;

    const consentLanguage =
      /** @type { string } */ (document.body.dataset.edtnLocale || 'en').split(
        '-',
      )[0] || 'en';

    window._sp_queue = [];
    window._sp_ = {
      // https://docs.sourcepoint.com/hc/en-us/articles/4405412419731-Client-configuration-parameters
      config: {
        accountId: cfg.accountId,
        baseEndpoint: 'https://cdn.privacy-mgmt.com',
        gdpr: {
          groupPmId: cfg.groupPmId,
        },
        usnat: cfg.groupPmIdUS
          ? {
              groupPmId: cfg.groupPmIdUS,
            }
          : undefined,
        consentLanguage,
        propertyId: cfg.propertyId,
        targetingParams: {
          edition,
          acps: this.isLoggedIntoContentpass() ? 'true' : 'false',
        },
        isSPA: !!window?.jsVars?.contentpassEnabled,

        /**
         * !!!IMPORTANT!!! make sure it's not set or is commented for production (propertyId will be used there)
         * this can be used to make it run locally. Also we need to enable it in development/sources.php
         * List of allowed domains available here https://portal.sourcepoint.com/dialogue/sites?tab=properties
         */
        // propertyHref: 'https://www.motor1.com',
        events: {
          onMessageChoiceSelect: (...args) => {
            console.log('[CMP SP] onMessageChoiceSelect', args);
          },
          onMessageReady: (...args) => {
            console.log('[CMP SP] onMessageReady', args);
          },
          onMessageChoiceError: (...args) => {
            console.log('[CMP SP] onMessageChoiceError', args);
          },
          onPrivacyManagerAction: (...args) => {
            console.log('[CMP SP] onPrivacyManagerAction', args);
          },
          onPMCancel: (...args) => {
            console.log('[CMP SP] onPMCancel', args);
          },
          onMessageReceiveData: (...args) => {
            console.log('[CMP SP] onMessageReceiveData', args);
          },
          onSPPMObjectReady: (...args) => {
            console.log('[CMP SP] onSPPMObjectReady', args);
          },
          onConsentReady: this.handleSPConsentReady.bind(this),
          onError: (...args) => {
            console.log('[CMP SP] onError', args);
          },
        },
      },
    };
  },

  handleSPConsentReady(messageType, consentUUID, eucConsent, settings = {}) {
    console.log('[CMP SP] onConsentReady', [
      messageType,
      consentUUID,
      eucConsent,
      settings,
    ]);

    // we always check for GDPR, even if it's USNAT
    // so when GDPR applies will block all content until consent is given
    // and in cases where it's USNAT, GDPR will have applies: false which will still initialize all required content
    if (messageType !== 'gdpr') return;

    const consentApplies = settings?.applies;

    // if (consentApplies && !settings.consentedToAll) {
    //   if (!this.isLoggedIntoContentpass()) {
    //     // we need to explain user why we keep showing him consent again and again
    //     // and this is the only way to show First layer message
    //     // window.location.reload();
    //     window._sp_.gdpr.loadPrivacyManagerModal();
    //   }
    //   return;
    // }

    // we can use something like this as a fallback for countries which don't have GDPR,but it's not optimal
    // waiting for response from SP
    if (!consentApplies) {
      // if consent is not required for current country, we need to update values is GCM (Google Consent Mode)
      const newGCM = {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
      };
      window.gtag?.('consent', 'update', newGCM);

      this.handleDevelopmentOrNotApplicable();
    }

    if (!this.isLoggedIntoContentpass()) {
      Array.from(document.querySelectorAll('.optanon-category-C0003')).forEach(
        (script) => {
          const newScript = document.createElement('script');
          newScript.src = script.src;
          newScript.id = script.id;
          newScript.type = 'text/javascript';
          script.replaceWith(newScript);
        },
      );
    }

    window.__tcfapi?.('addEventListener', 2, (tcData, listenerSuccess) => {
      // we will use this inside of GTM to figure out which groups are enabled
      const consentString = consentApplies
        ? Object.values(tcData.purpose.consents)
            .map((val) => (val ? '1' : '0'))
            .join('')
        : '1'.repeat(11);

      // we store values required for
      gtmDataLayer.push({
        event: 'spConsentUpdated',
        consentType: messageType,
        consentString,
        consentApplies,
        consentAll: settings.consentedToAll,
      });

      // if consent applies - we need to store new values and reinitialize widgets/embeds.
      if (consentApplies) {
        this.params = tcData;
        this.paramsChanged();
      }
    });
  },

  handleDevelopmentOrNotApplicable() {
    this.params = {
      purpose: {
        consents: {
          3: true,
        },
      },
    };
    this.paramsChanged();
  },

  getParams() {
    // if (!this.params) {
    //   this.params = {};
    // }

    return this.params || {};
  },

  isAllowed(code) {
    const params = this.getParams();

    return params?.applies || !!params?.purpose?.consents?.[code];
  },

  isAdsAllowed() {
    return this.isAllowed(this.CONSENT_CODE);
  },

  isSocialsAllowed() {
    return this.isAllowed(this.CONSENT_CODE);
  },

  isExcludedEdition() {
    return this.excludeEditions.includes(edition);
  },

  initEmbeds($page) {
    if (!this.usedMethods['initEmbeds'].has($page)) {
      this.usedMethods['initEmbeds'].set($page, $page);
    }

    if (
      this.currentEnvironment == 'development' ||
      this.currentEnvironment == 'stage' ||
      this.currentEnvironment == 'test'
    ) {
      if (this.initialized) {
        return;
      }
    } else {
      if (!this.initialized) {
        return;
      }
    }

    if (
      this.currentEnvironment === 'development' ||
      this.currentEnvironment == 'test'
    ) {
      initEmbeds($page);
      return;
    }

    if (!this.isSocialsAllowed()) {
      $page.find('.embed-item').each((index, element) => {
        $(element).empty().append(this.getStub());
      });

      $page.find('.ms-share_twitter').hide();
      return;
    }

    $page.find('.ms-share_twitter').show();
    initEmbeds($page);
    setInterval(function () {
      if (typeof instgrm === 'object') {
        instgrm.Embeds.process();
      }
    }, 2500);
  },

  initCommentsforCPusers($page) {
    const $body = $(document.body);
    const msntLoginScript = document.createElement('script');
    msntLoginScript.src =
      'https://accounts.motorsportnetwork.com/api/v1.0/script/81';

    if (!this.usedMethods['initCommentsforCPusers'].has($page)) {
      this.usedMethods['initCommentsforCPusers'].set($page, $page);
    }

    if (
      this.currentEnvironment == 'development' ||
      this.currentEnvironment == 'stage' ||
      this.currentEnvironment == 'test'
    ) {
      if (this.initialized) {
        return;
      }
    } else {
      if (!this.initialized) {
        return;
      }
    }

    if (!this.isAdsAllowed()) {
      $page.find('#comments-stub').each((index, element) => {
        $(element).empty().append(this.getStub());
      });
      return;
    } else {
      let commentsCounters = $page.find('.disqus-comment-count_wrapper');
      if (commentsCounters.length) {
        commentsCounters.each((index, element) => {
          $(element).css('display', 'inline-flex');
        });
        return;
      }

      $page.find('#comments-stub').each((index, element) => {
        $(element).remove();
      });
      if (edition == 'ar') {
        let articleCommentsCounter = $page.find(
          '.m1-article-meta_comments',
        );
        if (articleCommentsCounter.length) {
          articleCommentsCounter.css('display', 'inline-flex');
        }
      }
      $page
        .find('#comments .commentsNumber.show-disqus.show-coral')
        .css('display', 'inline-flex');
      if (edition != 'ar' && edition != 'ev_ar') {
        document.head.appendChild(msntLoginScript);
        topComments.init({
          config: { active: 1 },
        });
      }

      if (edition == 'ar' || edition == 'ev_ar') {
        loadCommentsNumber();
        commentsCountUpdate();
        loadDisqusComments();
        checkAndFilterCoralCommentsAmountToShow();
      }

      $page
        .find('#comments .commentsNumber.show-disqus.show-coral')
        .on('click', function (e) {
          const topCommentsBlockck = document.querySelector('.m1-top-comments');
          $(this).hide();
          $(topCommentsBlockck).hide();
        });
    }
  },

  initEmbedVideo($page) {
    if (!this.usedMethods['initEmbedVideo'].has($page)) {
      this.usedMethods['initEmbedVideo'].set($page, $page);
    }

    if (
      this.currentEnvironment == 'development' ||
      this.currentEnvironment == 'stage' ||
      this.currentEnvironment == 'test'
    ) {
      if (this.initialized) {
        return;
      }
    } else {
      if (!this.initialized) {
        return;
      }
    }

    if (!this.isAdsAllowed()) {
      setCookie('cpuserNoVideo', 1);
      $page.find('.video-player').each((index, element) => {
        if (!$(element).parent().hasClass('videoBoxArticle-wrapper')) {
          $(element).hide().parent().append(this.getStub());
        }
      });
      return;
    } else {
      setCookie('cpuserNoVideo', 0);
      disableAdsInEmbedVideo();
      $page.find('.video-player').each((index, element) => {
        if ($(element).parent().hasClass('videoBoxArticle-wrapper')) {
          var $videoWrapper = $('.videoBoxArticle .video-player');
          const clearSvg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <title>clear</title>
              <path d="M25.312 9.216l-7.424 7.456 7.424 7.424-1.888 1.888-7.424-7.456-7.424 7.456-1.888-1.888 7.424-7.424-7.424-7.456 1.888-1.856 7.424 7.424 7.424-7.424z"></path>
              </svg>`;
          if (!$videoWrapper.hasClass('other-videos-video-player')) {
            $videoWrapper.html(
              '<div class="video-player-close icon-cross">' +
                clearSvg +
                '</div><iframe allowfullscreen frameborder="0" allow="autoplay" src="' +
                $videoWrapper.data('video-url') +
                '"></iframe>',
            );
          }
        } else {
          $('.video-player').nextAll('.ms-onetrust_stub').remove();
          $(element).show();
        }
      });
    }
  },

  initShareBlock($page) {
    if (!this.usedMethods['initShareBlock'].has($page)) {
      this.usedMethods['initShareBlock'].set($page, $page);
    }

    if (
      this.currentEnvironment == 'development' ||
      this.currentEnvironment == 'stage' ||
      this.currentEnvironment == 'test'
    ) {
      if (this.initialized) {
        return;
      }
    } else {
      if (!this.initialized) {
        return;
      }
    }

    if (
      this.currentEnvironment === 'development' ||
      this.currentEnvironment == 'test'
    ) {
      $page.find('.shareBox').show();
      return;
    }

    if (!this.isSocialsAllowed()) {
      $page.find('.shareBox').hide();
      return;
    }

    $page.find('.shareBox').show();
  },

  paramsChanged() {
    for (const methodName in this.usedMethods) {
      if (
        this.usedMethods.hasOwnProperty(methodName) &&
        this.hasOwnProperty(methodName)
      ) {
        for (let params of this.usedMethods[methodName].values()) {
          this[methodName](params);
        }
      }
    }
  },

  getStub() {
    return `<div class="ms-onetrust_stub" data-widget="ms-onetrust_stub">
                <h3 class="ms-onetrust_stub-title">${i18n.cookie_settings}</h3>
                <p class="ms-onetrust_stub-text">${i18n.content_blocked}</p>
                <p class="ms-onetrust_stub-text">${i18n.click_to_show_content}</p>
                <div class="ms-onetrust_stub-button">${i18n.open_settings}</div>
              </div>`;
  },
};

window.msntConsent = msntConsent;

window.msntConsent.init();
