let result, loadingPromise;

const load = () => {
  if (result) return Promise.resolve(result);

  if (loadingPromise) return loadingPromise;

  loadingPromise = Promise.all([
    import('./cmp-dialog'),
  ])
    .then(([module]) => {
      result = module;

      return module;
    })
    .catch((err) => console.error(`Failed to load "cmpDialog" =>`, err));

  return loadingPromise;
};

const cmpDialog = {
  content: (message, title, callback, style, afterVisible) => {
    load().then(({ cmpDialog }) => {
        cmpDialog.content(message, title, callback, style, afterVisible);
    });
  },
  init: (config) => {
    load().then(({ cmpDialog }) => {
        cmpDialog.init(config);
    });
  },
};

export { cmpDialog };
