
export const topComments = {
  init(options) {
    options = $.extend(
      {
        selector: '.m1-top-comments',
        bodySelector: '.m1-top-comments__body',
        config: null,
      },
      options,
    );

    if (!options?.config?.active) return;

    this.render(options);
  },

  async render(options) {
    const $container = $(options.selector);
    const $containerBody = $container.find(options.bodySelector);
    if (!$containerBody.length) return;

    const result = await this.getData(options);
    const parentItems = new Set();

    const items =
      (result &&
      result.data &&
      result.data.data &&
      result.data.data.comments &&
      result.data.data.comments.nodes
        ? result.data.data.comments.nodes
        : []).filter((item) => {
          const id = item && item.rootParent && item.rootParent.id || item.id;

          if (parentItems.has(id)) return false;

          parentItems.add(id);

          return true;
        });

    if (!result.status || !items.length) {
      $container.addClass('m1-top-comments--empty');
      return;
    }

    $containerBody.html(this.getMarkup(items));
    this.showTopCommentSpoiler();
    this.insertAdsBlock();
  },

  getMarkup(items) {
    return `<div class="m1-top-comments__title">${i18n.top_comments_title || 'Top comments'}</div>
    <div class="m1-top-comments-items__wrap">
      <div 
        class="m1-top-comments-items" 
        data-lazy-content="comments-drawer-tpl"
      >
        ${items.map((item) => this.getItemMarkup(item)).join('\n')}
      </div>
    </div>`;
  },

  getItemMarkup(item) {
    const replies = item.replies.nodes;
    let repliesMarkup = '';
    if (replies && replies.length > 0) {
      repliesMarkup = `
          <div class="m1-top-comments-item__replies">
              ${replies
                .map((replyItem) => this.getRepliesItemMarkup(replyItem))
                .join('\n')}
          </div>`;
    }
    return `<div class="m1-top-comments-item">
              <div class="m1-top-comments-item__head">
                ${this.getAvatar(item.author)}

                <div class="m1-top-comments-item__commenter">
                  <span class="m1-top-comments-item__commenter-username">${
                    item.author.username
                  }</span>
                  <span class="m1-top-comments-item__commenter-date">${this.formatDate(
                    item.createdAt,
                  )}</span>
                </div>
              </div>

              <div class="m1-top-comments-item__body">
                ${item.body}
                ${repliesMarkup}
              </div>
            </div>`;
  },


  getRepliesItemMarkup(item) {
    return `<div class="m1-top-comments-replie-item">
      <div class="m1-top-comments-replie-item__head">

        <div class="m1-top-comments-replie-item__commenter">
          ${this.getAvatar(item.author)}
          <div class="m1-top-comments-replie-item__commenter-text">
            <span class="m1-top-comments-replie-item__commenter-username">${
              item.author.username
            }</span>
            <span class="m1-top-comments-replie-item__commenter-date">${this.formatDate(
              item.createdAt,
            )}</span>
          </div>
        </div>
      </div>

      <div class="m1-top-comments-replie-item__body">
        ${item.body}
      </div>
    </div>`;
  },

  formatDate(date) {
    const data = document.body.dataset;
    const curentEdtn = data['edtn'];
    const editions = {
      ra_us: 'en-US',
      fr: 'fr-FR',
      us: 'en-US',
      ev_us: 'en-US',
      ev_br: 'pt-BR',
    };
    const defaultLocale = 'en-US';
    const locale = editions[curentEdtn] || defaultLocale;

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'always' });
    
    const now = new Date();
    const commentDate = new Date(date);
    const diffInSeconds = (now - commentDate) / 1000;

    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInWeek = 7 * secondsInDay;
    const secondsInMonth = 30 * secondsInDay;
    const secondsInYear = 12 * secondsInMonth;

    if (diffInSeconds < secondsInMinute) {
        return rtf.format(-Math.round(diffInSeconds), 'second');
    } else if (diffInSeconds < secondsInHour) {
        const minutes = diffInSeconds / secondsInMinute;
        return rtf.format(-Math.round(minutes), 'minute');
    } else if (diffInSeconds < secondsInDay) {
        const hours = diffInSeconds / secondsInHour;
        return rtf.format(-Math.round(hours), 'hour');
    } else if (diffInSeconds < secondsInWeek) {
        const days = diffInSeconds / secondsInDay;
        return rtf.format(-Math.round(days), 'day');
    } else if (diffInSeconds < secondsInMonth) {
        const weeks = diffInSeconds / secondsInWeek;
        return rtf.format(-Math.round(weeks), 'week');
    } else if (diffInSeconds < secondsInYear) {
        const months = diffInSeconds / secondsInMonth;
        return rtf.format(-Math.round(months), 'month');
    } else {
        const years = diffInSeconds / secondsInYear;
        return rtf.format(-Math.round(years), 'year');
    }
  },

  getAvatar(author) {
    if (!author.avatar) return '';

    return `<img 
      src="${author.avatar}" 
      class="m1-top-comments-item__img"
      width="40px"
      height="40px"
      alt="${author.username}"
    />`;
  },

  async getData(options) {
    const host = window.location.host;
    var $comments = $(options.selector).not('[data-comments-ready="1"]');
    if (!$comments.length) return;

    const data = $comments.data();
    const { articleId } = data;

    let { edtn } = document.body.dataset;

    if (host === 'ra-s.motor1.com') {
      edtn = 'ra_us_s';
    }

    return $.get({
      url: `https://y14vyglkj5.execute-api.us-east-1.amazonaws.com/default/coral-comment-proxy?storyId=${articleId}&editionCode=${edtn}`,
    });
  },

  showTopCommentSpoiler() {
    const spoilers = document.querySelectorAll('.coral-rte-spoiler');

    if (spoilers) {
      spoilers.forEach(spoiler => {
        spoiler.addEventListener('click', () => {
          spoiler.classList.add('showSpoiler');
        });
      });
    }
  },

  insertAdsBlock() {
    const template = document.querySelector('#coral-top-comment-ad-template');
    const topCommentsWrap = document.querySelector('.m1-top-comments__wrapper');
    const topCommentItemBeforeAd = topCommentsWrap.querySelector('.m1-top-comments-item');
    if (!template) return;
    const html = template.innerHTML;
    topCommentItemBeforeAd.insertAdjacentHTML('afterend', html);
  },
};
